import Common from '@/assets/js/common.js'
import ActeTransformer from '@/assets/js/dexie/transformers/ActeTransformer.js'
import HorseTransformer from '@/assets/js/dexie/transformers/HorseTransformer.js'
import RaceTransformer from '@/assets/js/dexie/transformers/RaceTransformer.js'
import ActeTypeTransformer from '@/assets/js/dexie/transformers/ActeTypeTransformer.js'
import ActeTypeReferenceTransformer from '@/assets/js/dexie/transformers/ActeTypeTransformer.js'
import SanitaireTransformer from '@/assets/js/dexie/transformers/SanitaireTransformer.js'
import _isEmpty from 'lodash/isEmpty'
import _orderBy from 'lodash/orderBy'
import ActeGroupeTransformer from '@/assets/js/dexie/transformers/ActeGroupeTransformer.js'
import Gynecologie from "@/mixins/Gynecologie.js"
import ResultatAnalyse from "@/mixins/ResultatAnalyse.js"
import CroissanceMixin from "@/mixins/Croissance.js"
import TemperatureMixin from "@/mixins/Temperature.js"
import Embryon from "@/mixins/Embryon.js"
import Constants from 'Constants'
import HorseActesCleaner from '@/assets/js/cache/cleaners/HorseActesCleaner'

var ActesMixin = {
    mixins: [Gynecologie, ResultatAnalyse, CroissanceMixin, TemperatureMixin, Embryon],
	methods: {
		// Utilisé pour récupérer tous les ID d'actes types d'un groupe
		getGroupActesTypes(groupLabel) {
			const group = this.actes_groupes.find(g => g.actesgroupe_label === groupLabel)
			if (group) {
				return group.actestypes.map(type => type.actestype_id)
			}
			else {
				let types = []
				this.actes_groupes.forEach(g => {
					types = types.concat(
						g.actestypes.map(type => type.actestype_id)
					)
				})
				return types
			}
		},

        getGynecoActsFromHorse: async function(horse_id, begin, end) {
            return this.$storage.db.t('horse_actes')
            .then(table => {
                return table.where('actes_horse').equals(parseInt(horse_id))
				.and(acte => {
					return begin <= acte.actes_date && acte.actes_date <= end
				})
				.transform(new ActeTransformer('gyneco'))
            })
			.then(res => {
				res.forEach(acte => {
					acte.og = acte.reponse.og
					acte.od = acte.reponse.od
					acte.dg = acte.reponse.dg
					acte.uterus = acte.reponse.uterus
					acte.stallion = acte.reponse.stallion
					acte.col = acte.reponse.col
					acte.chaleur = acte.reponse.chaleur
					acte.ovulation = acte.reponse.ovulation
				})

				return res
			})
        },

		getActes: async function(acte_ids) {
			acte_ids = this.$sync.replaceWithReplicated('horse_actes', acte_ids)

			return this.$storage.db.t('horse_actes')
			.then(table => {
				return table.where('actes_id').anyOf(acte_ids).toArray()
			})
		},

        // Récupération d'un seul acte à partir d'un ID
        getActeFromId: async function(acte_id, transformFn='heavy') {
            return this.$storage.db.t('horse_actes')
            .then(table => {
                return table.get(parseInt(acte_id))
            })
            .then(acte => {
                return ActeTransformer.process(acte, transformFn)
			})
        },

        getActeWithReponse: async function(acte_id) {
            return this.$storage.db.t('horse_actes')
            .then(table => {
                return table.get(parseInt(acte_id))
            })
            .then(acte => {
                return ActeTransformer.process(acte, 'withReponseNotFormatted')
            })
        },

        async removeArticles(acte_id) {
            if (!this.$store.state.userAccess.hasFacturationAccess && !this.$store.state.userAccess.hasInheritedlicenceFacturationAccess) {
                return
            }

            let actes_id = this.$sync.replaceWithReplicated('horse_actes', acte_id)
            await this.$storage.db.t('horse_actes_articles')
            .then(table => {
                return table
                    .where('actesarticles_actes')
                    .equals(actes_id)
                    .invalid()
            })
        },
        async saveArticles(article_acte, actes_id) {
			if (!this.$store.state.userAccess.hasFacturationAccess && !this.$store.state.userAccess.hasInheritedlicenceFacturationAccess) {
				return
			}

			// Car dans un forEach le prix est multiplié par 100
			article_acte = this.deepClone(article_acte)

            if (!_isEmpty(article_acte)) {
                let article = await this.$storage.db.t('articles')
                .then(table => {
                    return table.get(parseInt(article_acte.id))
                })

                let tva = await this.$storage.db.t('vat')
                .then(table => {
                    return table.get(parseInt(article.articles_vat))
                })

                if(article_acte.ht != null)
                {
                    article_acte.ht = article_acte.ht.replace(",", ".")
                    article_acte.ht = article_acte.ht.replace(/\s/g, '') * 100
                    article.articles_ht = article_acte.ht
					let ttc = article_acte.ht * (1 + tva.vat_value)
					if(ttc.toString().indexOf(".") > -1){
						ttc = ttc.toString().substr(0, ttc.toString().indexOf(".")).toFloat()
					}
                    article.articles_ttc = ttc
                }
                else
                {
                    article.articles_ht = 0
                    article.articles_ttc = 0
                }

                await this.$storage.db.t('horse_actes_articles')
                .then(table => {
                    return table.add({
                        actesarticles_id: Common.getNegativeId(),
                        actesarticles_actes: parseInt(actes_id),
                        actesarticles_articles: article.articles_id,
                        actesarticles_ht: article.articles_ht,
                        actesarticles_ttc: article.articles_ttc,
                        actesarticles_vat: article.articles_vat,
                        actesarticles_quantite: article_acte.qte || 1,
                        actesarticles_valide: 1,
						actesarticles_commentaire: article_acte.commentaire
                    })
                })
            }
        },

        async saveReponses(reponses, actes_id) {
            await this.$storage.db.t('reponse')
            .then(table => {
                return table.where({
                    reponse_fk: parseInt(actes_id),
                    reponse_type: 'horse_actes'
                }).invalid()
            })

            if (reponses) {
                await Common.asyncForEach(reponses, async (reponse) => {
                    await this.$storage.db.t('reponse')
                    .then(table => {
                        return table.add({
                            reponse_id: Common.getNegativeId(),
                            reponse_question: reponse.reponse_question,
                            reponse_fk: parseInt(actes_id),
                            reponse_type: 'horse_actes',
                            reponse_valide: 1,
                            reponse_data: reponse.reponse_data,
                            reponse_data_hidden: reponse.reponse_data_hidden
                        })
                    })
                })
            }
        },

        // OPERATIONS CRUD
        addActes: async function(horse_ids, acte_statut, acte_date, acte_commentaire, acte_type, acte_reference, actes_accountingplan, articles, reponses, triggerentity = null, contact_id) {
			let tables = ['horse_actes', 'reponse']
			if (this.$store.state.userAccess.hasFacturationAccess || this.$store.state.userAccess.hasInheritedlicenceFacturationAccess) {
				tables.push('articles')
				tables.push('horse_actes_articles')
                tables.push('vat')
			}

			let contact_id_pos = contact_id
			if(contact_id_pos < 0) {
				await this.$sync.force(true, true)
				contact_id_pos = this.$sync.replaceWithReplicated('contact', contact_id_pos)
			}

			return this.$storage.db.transaction(
                'rw',
                tables,
                () => {
                    let inserted_actes = []
                    horse_ids.forEach((horse_id) => {
                        const actes_id = Common.getNegativeId()
                        inserted_actes.push(actes_id)
                        this.$storage.db.t('horse_actes')
                        .then(table => {
                            return table.add({
                                actes_id: actes_id,
                                actes_horse: parseInt(horse_id),
                                actes_actesstatut: acte_statut,
                                actes_date: acte_date,
								actes_time: acte_date.toTimeString(),
                                actes_actestype: acte_type,
                                actes_accountingplan: actes_accountingplan,
                                actes_reference: acte_reference || 0,
                                actes_commentaire: acte_commentaire,
                                actes_show_commentaire: 0,
                                actes_valide: 1,
								actes_triggerentity: triggerentity ? parseInt(triggerentity) : null,
								actes_invoiceable: 1,
                                actes_contact: contact_id_pos
                            })
                        })

                        if(articles.length > 0) {
                            for (let i = 0; i < articles.length; i++)
                            {
                                this.saveArticles(articles[i], actes_id)
                            }
                        }

						this.saveReponses(reponses, actes_id)
                    })
					HorseActesCleaner.inst().onMutationHorse(horse_ids)
                    return inserted_actes
                }
            )
    	},

        // Efface une liste d'actes à partir d'ids
        deleteActes: async function(actes_ids, horse_ids) {
            // Transformation en entiers
            actes_ids = this.$sync.replaceWithReplicated('horse_actes', actes_ids)

			await this.$storage.db.t('reponse')
			.then(table => {
				return table.where('reponse_fk').anyOf(actes_ids)
				.and((rep) => (rep.reponse_type === 'horse_actes'))
				.invalid()
			})

			await this.$storage.db.t('horse_actes_result')
			.then(table => {
				return table.where('result_acte')
				.anyOf(actes_ids)
				.invalid()
			})

			await this.$storage.db.t('horse_actes')
			.then(table => {
				return table.where('actes_id')
				.anyOf(actes_ids)
				.invalid()
			})

			HorseActesCleaner.inst().onMutationHorse(horse_ids)
        },

        /* champs => Array{String} des colonnes à updates  |  valeurs => Array{String} des données à maj */
        updateActe: async function(acte_ids, horse_ids, acte_statut, acte_date, acte_commentaire, acte_type, acte_reference, actes_accountingplan, articles, reponses, contact_id, no_edit_date = false) {
            // Transformation en entiers
			acte_ids = this.$sync.replaceWithReplicated('horse_actes', acte_ids)
			let tables = ['horse_actes', 'reponse']
			if (this.$store.state.userAccess.hasFacturationAccess || this.$store.state.userAccess.hasInheritedlicenceFacturationAccess) {
				tables.push('articles')
				tables.push('horse_actes_articles')
                tables.push('vat')
			}

            return this.$storage.db.transaction(
                'rw',
                tables,
                () => {
					acte_ids.forEach((acte_id) => {
						let params = {
							actes_actestype: acte_type,
							actes_accountingplan: actes_accountingplan,
							actes_reference: acte_reference || 0,
							actes_commentaire: acte_commentaire,
							actes_show_commentaire: 0,
                            actes_contact: contact_id
						}

						if(!no_edit_date) {
							params.actes_actesstatut = acte_statut
							params.actes_date = acte_date
							params.actes_time = acte_date.toTimeString()
						}

						this.$storage.db.t('horse_actes')
						.then(table => {
							return table.update(parseInt(acte_id), params)
						})
                        this.removeArticles(acte_id)
						if(articles.length > 0)	{
							for (let i = 0; i < articles.length; i++) {
								this.saveArticles(articles[i], acte_id)
							}
						}
						this.saveReponses(reponses, acte_id)
					})
					HorseActesCleaner.inst().onMutationHorse(horse_ids)
                }
            )
        },

		/* Replanification d'une liste d'actes */
        replanActe: async function(acte_ids, date) {
            acte_ids = this.$sync.replaceWithReplicated('horse_actes', acte_ids)

            return this.$storage.db.t('horse_actes')
            .then(table => {
                return table.where('actes_id')
                .anyOf(acte_ids)
                .modify({
                    actes_date: date
                })
            })
        },

        // Données statiques - horse_actes_groupe
		async getActesGroupes(gamme_filter=true, light=false) {
            let groupes = await this.$storage.db.t('horse_actes_groupe')
            .then(table => {
                return table.toCollection()
            })
            .then(col => {
            	if(light) {
            		return col.transform(new ActeGroupeTransformer('withTypesLight'))
            	}
                return col.transform(new ActeGroupeTransformer('withTypesArticlesLight'))
            })

            // Si on veut filtrer les groupes par rapport à la gamme de licence
            if(gamme_filter) {
				// Si des groupes d'actes ne doivent pas etre disponible au public, il faut
				// ajouter les codes à cette liste
				const blacklist = []

				// Recherche des index des groupes
				const indexes = []
				groupes.forEach((groupe, index) => {
					// Le groupe est dans la blacklist
					if (blacklist.indexOf(groupe.actesgroupe_code) !== -1) {
						indexes.push(index)
					}
				})

				// Suppression des groupes en triant les index pour éviter
				// les problèmes de suppression du mauvais item
				// On donne une fonction spéciale pour interpréter en nombre et pas en string
				indexes.sort((a, b) => (a - b)).reverse().forEach(index => {
					groupes.splice(index, 1)
				})
            }

            return groupes
        },

        // Données statiques - horse_actes_type
		getActesTypes(groupe_code) {
			return this.$storage.db.t('horse_actes_type')
			.then(table => {
				if (groupe_code) {
					return table.where('actestype_codegroupe').equals(groupe_code)
				}
				else {
					return table.orderBy('actestype_label')
				}
			})
			.then(col => {
				return col.transform(new ActeTypeTransformer())
			})
    	},

        // Actes type avec détails (groupe lié)
		getActesTypesDetails(transformer = 'withGroups') {
			return this.$storage.db.t('horse_actes_type')
			.then(table => {
				return table.orderBy('actestype_label')
			})
			.then(col => {
				return col.transform(new ActeTypeTransformer(transformer))
			})
        },

        // Données statiques - horse_actes_type_reference
		getActesTypesReferences() {
			return this.$storage.db.t('horse_actes_type_reference')
			.then(table => {
				return table.orderBy('actestypereference_label')
			})
			.then(col => {
				return col.transform(new ActeTypeReferenceTransformer())
			})
        },

        // Données statiques - horse_actes_type
        getActeTypeFromId: function(type_id) {
			return this.$storage.db.t('horse_actes_type')
			.then(table => {
				return table.get(parseInt(type_id))
			})
			.then(item => {
				return ActeTypeTransformer.process(item)
			})
    	},

        getActeTypeByCode: async function(code) {
            let acte_type = await this.$storage.db.t('horse_actes_type')
            .then(table => {
                return table.where({actestype_code: code})
            })
            .then(col => {
                return col.transform(new ActeTypeTransformer('withGroups'))
            })
            return acte_type[0]
		},

		refreshTable() {
			this.shutterPanel().reload('act-history')
			this.shutterPanel().reload('act-horse')
			this.shutterPanel().reload('horse-gyneco')
		},

        /* Action : Suppression d'acte(s) */
        async applyDeleteActes(data) {
			const acte_ids = data.map((acte) => { return acte.actes_id })
			const horse_ids = data.map((acte) => { return acte.actes_horse })

			try {
				await this.deleteActes(acte_ids, horse_ids)
				this.refreshTable()
				this.successToast()
			}
			catch(e) {
				console.error(e)
				this.failureToast()
			}
        },

        /* Replanification en lot */
		async applyReplanActe(data) {
            const date = data.date
            const acte_ids = this.interacted_actes.map((acte) => { return acte.actes_id })

            try {
                await this.replanActe(acte_ids, date)
				this.refreshTable()
                this.successToast()
            }
            catch(e) {
                console.error(e)
                this.failureToast()
            }
        },

		async getSeasonMareStallionFromActe(actes_id) {
			if(!actes_id) {
				return null
			}

			const acte = await this.$storage.db.t('horse_actes')
			.then(table => {
				return table.get(parseInt(actes_id))
			})

			if(!acte) {
				return null
			}

			const question_etalon = await this.$storage.db.t('question')
			.then(table => {
				return table.where({
					question_type: 'horse_actes_type',
					question_fk: acte.actes_actestype,
					question_code: 'Étalon'
				})
				.toArray()
			})
			.then(questions => {
				return questions.length > 0 ? questions[0] : null
			})

			if(!question_etalon) {
				return null
			}

			const reponse_etalon = await this.$storage.db.t('reponse')
			.then((table) => {
				return table.where({
					reponse_type: 'horse_actes',
					reponse_fk: this.$sync.replaceWithReplicated('horse_actes', actes_id),
					reponse_question: question_etalon.question_id
				})
				.toArray()
			})
			.then(reponses => {
				return reponses.length > 0 ? reponses[0] : null
			})

			if(!reponse_etalon) {
				return null
			}

			let seasonmarestallion_id = null
			if(reponse_etalon.reponse_data_hidden && reponse_etalon.reponse_data_hidden.type === 'season_mare_stallion') {
				seasonmarestallion_id = reponse_etalon.reponse_data_hidden.id
			}

			if(!seasonmarestallion_id) {
				return null
			}

			return this.$storage.db.t('season_mare_stallion')
			.then(table => {
				return table.get(seasonmarestallion_id)
			})
		},

		async setActePoulain(actes_id, foal_id) {
			if(!actes_id) {
				return
			}

			const reponses = await this.$storage.db.t('reponse')
			.then(table => {
				return table.where({
					reponse_type: 'horse_actes',
					reponse_fk: this.$sync.replaceWithReplicated('horse_actes', actes_id)
				})
				.toArray()
			})

			const question_ids = reponses.map(r => (r.reponse_question))

			const questions = await this.$storage.db.t('question')
			.then(table => {
				return table.where('question_id').anyOf(question_ids).toArray()
			})

			const question_resultat = questions.find((q) => (q.question_code === 'Résultat'))

			// La réponse résultat n'est pas complétée
			if (!question_resultat) {
				return
			}

			await Common.asyncForEach(reponses, async reponse => {
				// C'est la réponse du résultat
				if(reponse.reponse_question === question_resultat.question_id) {
					await this.$storage.db.t('reponse')
					.then(table => {
						return table.update(reponse.reponse_id, {
							reponse_data_hidden: {
								type: 'horse',
								id: parseInt(foal_id)
							}
						})
					})
				}
			})
		},

        async checkPoulinage(actes_id) {
			let checks = []

			actes_id.forEach(acte_id => {
				const promise = async () => {
					const acte = await this.getActeFromId(acte_id)

                    if(acte.actes_actesstatut === 1) {
                        if(acte.actetype.actestype_code === "REPRO_POULINAGE" && acte.reponse.length > 0) {
                            const rep_sms = acte.reponse.reponse_hidden_data.find(rep => rep.type == "season_mare_stallion")
                            if(rep_sms) {
                                await this.saveDateTerme(acte.actes_horse, acte.actes_date, rep_sms.id)
                            }
                        }

						// if(acte.actetype.actestype_code === "REPRO_DIAGNOSTIC_DE_GESTATION") {
						// 	const season_mare_stallion = await this.getSeasonMareStallionFromActe(acte_id)
						// 	if(season_mare_stallion){
						// 		await this.recalculDateTerme(season_mare_stallion, acte)
						// 	}
                        // }
                    }
				}

				checks.push(promise())
			})

			await Promise.all(checks)
		},

		async getLastHorseActs(horse_id) {
			return this.$storage.db.t('horse_actes')
				.then(table => {
					return table.where({ actes_horse: parseInt(horse_id) })
						.sortBy('actes_date')
				})
				.then(col => {
					return col.reverse()
				})
				.then(res => {
					// const tmp = res.splice(0, 20) SUPPORT-25962
					return ActeTransformer.process(res, 'inlineFormatted')
				})
				.then(res => {
					return _orderBy(res, ['actes_date'], ['desc'])
				})
		},

        async checkAnalyse(actes_id, actestype_code = null) {
            const type_analyse = [
                "TEST_NEW2_ANMIE_INFCTIEUSE_DES_EQUIDS_AIE",
                "TEST_NEW2_MTRITE_CONTAGIEUSE_QUINE_MEC_POUR_TALON",
                "TEST_NEW2_MTRITE_CONTAGIEUSE_QUINE_MEC_POUR_JUMENT",
                "TEST_NEW2_ARTRITE_VIRALE_QUINE_EVA_POUR_JUMENT",
                "TEST_NEW2_ARTRITE_VIRALE_QUINE_EVA_POUR_TALON"
            ]
            if(actestype_code && !type_analyse.includes(actestype_code)) {
            	return false
            }

            const acte_negatif = actes_id.filter(id => id < 0)
            if(acte_negatif.length > 0) {
                await this.$sync.force(true, true)
            }

            let analyses = []

            await Common.asyncForEach(actes_id, async (acte_id) => {
                const acte = await this.$storage.db.t('horse_actes')
                    .then(table => {
                        return table.get(parseInt(acte_id))
                    })
                    .then(acte => {
                        return ActeTransformer.process(acte, 'withReponseNotFormatted')
                    })
				if(acte) {
					if(type_analyse.includes(acte.actestype_code)) {
						const result_type = await this.$storage.db.t('horse_actes_result_type')
							.then(table => {
								return table.where({ resulttype_actetype : acte.actestype_code })
							})
							.then(col => {
								return col.transform(new SanitaireTransformer("typeWithQuestion"))
							})
							.then(arr => {
								return arr[0]
							})

						let reponse = []
						result_type.question.forEach(question => {
							reponse.push({
								reponse_question: question.question_id,
								reponse_data: ["En cours"],
								reponse_data_hidden: {type: "resultat", code: "ENCOURS"}
							})
						})

						let analyse = acte.reponse.find(rep => rep.reponse_question_code == "Analyses demandées")
						let matrice = acte.reponse.find(rep => rep.reponse_question_code == "Site de prélèvement")
                        if(!analyse.reponse_data.every(el => el === null) && !matrice.reponse_data.every(el => el === null)) {
                        	analyses.push({
                        		resulttype_id: result_type.resulttype_id,
                        		acte_id: this.$sync.replaceWithReplicated('horse_actes', acte_id),
                        		reponses: reponse,
                        		analyses: analyse.reponse_data,
                        		matrices: matrice.reponse_data,
                        		horse_id: acte.actes_horse
                        	})
                            // await this.saveResultAnalyse(result_type.resulttype_id, acte_id, reponse, analyse.reponse_data, matrice.reponse_data, acte.actes_horse)
                        }
					}
				}
            })

            await this.saveResultAnalyseLot(analyses)
            await this.$sync.force(true, true)
        },

		checkDateExists: async function(actes_id, horse_id, actestype_id, date) {
			let actes_date = Date.parseTz(date).toLocaleDateString(this.$i18n.locale())

			let nb_actes = await this.$storage.db.t('horse_actes')
				.then(table => {
					return table.where({
						actes_actestype: parseInt(actestype_id),
						actes_horse: parseInt(horse_id)
					})
					.and(acte => acte.actes_id !== actes_id)
					.and(acte =>
						Date.parseTz(acte.actes_date).toLocaleDateString(this.$i18n.locale()) == actes_date
					)
				})
				.then(res => {
					return res.count()
				})

			if(nb_actes > 0) {
				this.infoToast('toast.info_acte_same_day')
			}
		},

        checkCroissance: async function(actes_ids) {
            let croissances = []

            await Common.asyncForEach(actes_ids, async (acte_id) => {
                const acte = await this.$storage.db.t('horse_actes')
                    .then(table => {
                        return table.get(parseInt(acte_id))
                    })
                    .then(acte => {
                        return ActeTransformer.process(acte, 'withReponseNotFormatted')
                    })

                if(acte.actes_actesstatut === 1 && acte.actetype.actestype_code === "EVAL_PESEE_TOISE" && acte.reponse.length > 0) {
                    const rep_perimetre = acte.reponse.find(rep => rep.reponse_question_code == "Périmètre thoracique")
                    const rep_garrot = acte.reponse.find(rep => rep.reponse_question_code == "Hauteur au garrot")
                    const rep_poids = acte.reponse.find(rep => rep.reponse_question_code == "Masse")
                    const rep_pouliniere = acte.reponse.find(rep => rep.reponse_question_code == "Poulinière")
                    const rep_nec = acte.reponse.find(rep => rep.reponse_question_code == "NEC")
                    const rep_nec_avance = acte.reponse.find(rep => rep.reponse_question_code == "NEC_avance")
                    const rep_nec_garrot = acte.reponse.find(rep => rep.reponse_question_code == "NEC garrot")
                    const rep_nec_epaule = acte.reponse.find(rep => rep.reponse_question_code == "NEC arrière de l'épaule")
                    const rep_nec_cote = acte.reponse.find(rep => rep.reponse_question_code == "NEC côtes")

					await this.$sync.force()
					const id = this.$sync.replaceWithReplicated('horse', acte.actes_horse)

					const horse = await this.$storage.db.t('horse')
					.then(table => {
						return table.where('horse_id').equals(parseInt(id))
					})
					.then(col => {
						return col.first()
					})

					const race = await this.$storage.db.t('horse_race')
					.then(table => {
						return table.where('race_code').equals(horse.horse_race)
					})
					.then(col => {
						return col.first()
					})

					let Difference_In_Time = acte.actes_date.getTime() - horse.horse_datenaissance.getTime(); 
					let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24); 
					let day = Math.round(Difference_In_Days);

					const horse_perimetre = rep_perimetre.reponse_data[0] ? rep_perimetre.reponse_data[0] : null
                    const horse_garrot = rep_garrot.reponse_data[0] ? rep_garrot.reponse_data[0] : null
                    const horse_poids = rep_poids.reponse_data[0] ? rep_poids.reponse_data[0] : null
                    let horse_nec = rep_nec.reponse_data[0] ? rep_nec.reponse_data[0] : null
                    let horse_pouliniere = null
                    if(rep_pouliniere && rep_pouliniere.reponse_data[0]) {
						horse_pouliniere = rep_pouliniere.reponse_data[0]
                    }
					
					let params = {
						date_mesure: acte.actes_date,
						horse_perimetre: rep_perimetre.reponse_data[0] ? rep_perimetre.reponse_data[0] : null,
						horse_garrot: rep_garrot.reponse_data[0] ? rep_garrot.reponse_data[0] : null,
						horse_poids: rep_poids.reponse_data[0] ? rep_poids.reponse_data[0] : null,
						horse_nec: horse_nec
					}

					let horse_nec_avance = null
					let horse_nec_garrot = null
					let horse_nec_epaule = null
					let horse_nec_cote = null
					
                    if(rep_nec_avance && rep_nec_avance.reponse_data[0]) {
						horse_nec_avance = rep_nec_avance.reponse_data[0]
                    }
					if(horse_nec_avance){
						horse_nec_garrot = rep_nec_garrot.reponse_data[0] ? rep_nec_garrot.reponse_data[0].replace(',', '.') : null
						horse_nec_epaule = rep_nec_epaule.reponse_data[0] ? rep_nec_epaule.reponse_data[0].replace(',', '.') : null
						horse_nec_cote = rep_nec_cote.reponse_data[0] ? rep_nec_cote.reponse_data[0].replace(',', '.') : null

						// Calcul NEC
						if ( !horse_nec && horse_nec_garrot && horse_nec_epaule && horse_nec_cote ){
							horse_nec = ((horse_nec_garrot * 15) + (horse_nec_epaule * 25) + (horse_nec_cote * 60)) / 100
						}
							params['horse_nec_garrot'] = horse_nec_garrot
							params['horse_nec_epaule'] = horse_nec_epaule
							params['horse_nec_cotes'] = horse_nec_cote
					}


					if(!horse_poids && horse_garrot && horse_perimetre){
						let horse_poids_estimated = 0
						if (day >= 1460){
							//poney 
							if(race.race_type.includes("4")){
								horse_poids_estimated = 3.56 * horse_garrot + 3.65 * horse_perimetre - 714,66
							}
							//chevaux de trait
							if (race.race_type.includes("5")){
								horse_poids_estimated = 7.3 * horse_perimetre - 800
							}
							//pouliniere de sport
							else if (!race.race_type.includes("4") && !race.race_type.includes("5") && !race.race_type.includes("6") && horse_pouliniere == true){
								horse_poids_estimated = 2.6 * horse_garrot + 5.2 * horse_perimetre - 855
							}
							//chevaux de sport
							else {
								horse_poids_estimated = 3 * horse_garrot + 4.3 * horse_perimetre - 785
							}
						} else if (day >= 730){
							horse_poids_estimated = 4.5 * horse_perimetre - 370
						} else {
							if(horse.horse_sexe == "F" && horse_garrot) {
							horse_poids_estimated = (0.21 * day) + (1.85 * horse_garrot) + (2.01 * horse_perimetre) - 330.2
							}
							else if((horse.horse_sexe == "M" || horse.horse_sexe == "H") && horse_garrot) {
								horse_poids_estimated = (0.20 * day) + (1.72 * horse_garrot) + (2.15 * horse_perimetre) - 327
							}
							else {
								horse_poids_estimated = 4.5 * horse_perimetre - 370
							}
						}
					
						horse_poids_estimated = horse_poids_estimated.toFixed(2)

						params.horse_poids_estimated = horse_poids_estimated ? horse_poids_estimated : null
                    	
					}

                    croissances.push(await this.addCroissance(id, params))
                }
            })

            await Promise.all(croissances)
        },

        checkTemperature: async function(actes_ids) {
            let temperatures = []

            await Common.asyncForEach(actes_ids, async (acte_id) => {
                const acte = await this.$storage.db.t('horse_actes')
                    .then(table => {
                        return table.get(parseInt(acte_id))
                    })
                    .then(acte => {
                        return ActeTransformer.process(acte, 'withReponseNotFormatted')
                    })

                if(acte.actes_actesstatut === 1 && acte.actetype.actestype_code === "SUIVI_TEMPERATURE" && acte.reponse.length > 0) {
                    const rep_temperature = acte.reponse.find(rep => rep.reponse_question_code == "Temperature")

                    const params = {
						time_mesure: acte.actes_time,
                        date_mesure: acte.actes_date,
                        horse_temperature: rep_temperature.reponse_data[0] ? rep_temperature.reponse_data[0] : null,
                    }

                    await this.$sync.force()
                    const id = this.$sync.replaceWithReplicated('horse', acte.actes_horse)
                    temperatures.push(await this.addTemperature(id, params))
                }
            })

            await Promise.all(temperatures)
        },

		// retourne les actes validés d'un type et d'un cheval sur les 6 derniers mois
		getActeByTypeAndHorse(type_id, horse_id) {
			var date = new Date();
			date.setMonth(date.getMonth() - 6);

			return this.$storage.db.t('horse_actes')
				.then(table => {
					return table.where({
						actes_actestype: parseInt(type_id),
						actes_horse: parseInt(horse_id),
						actes_actesstatut: 1
					})
					.filter(acte => (Date.parseTz(acte.actes_date) >= Date.parseTz(date)))
					.reverse()
					.sortBy('actes_date')
				})
		},

		/* CLIENT AREA */

		getActesHistory: async function(start_date, end_date, actes_types) {
			const params = {
				'start': start_date,
				'end': end_date,
				'actes_types': actes_types.join(',')
		 	}
			const url = this.constructRoute(Constants.ACTES_HIST_URL, params)+"&licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("ActesMixin::getActesHistory", url)
			.catch(e => {
                console.error("ActesMixin::getActesHistory => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		getActesHistoryCa: async function(start_date, end_date) {
			const params = {
				'start': start_date,
				'end': end_date
		 	}
			const url = this.constructRoute(Constants.ACTES_HIST_CA_URL, params)+"&licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("ActesMixin::getActesHistory", url)
			.catch(e => {
                console.error("ActesMixin::getActesHistory => ", e)
                return null
			})

            if(result) return result.retour
            return []
		},

		notifyClient: async function(acte_ids, model_id) {
			const params = {
				acte_ids: acte_ids,
				model_id: model_id
			}
			const url = Constants.ACTES_NOTIFY_CLIENT+"?licence_key="+Constants.USER_LICENCE_KEY
            await this.$request.request_post_api("ActesMixin::notify_client", url, params, false)
            .catch(error => {
                console.error("ActesMixin::notify_client => ERROR", error)
				throw error
            })
		},

        getActesByHorses(horse_ids) {
            return this.$storage.db.t('horse_actes')
            .then(table => {
                return table.where('actes_horse').anyOf(horse_ids)
            })
            .then(acte => {
                return ActeTransformer.process(acte, 'gyneco')
            })
        },

        updateOperateurActes: async function(acte_ids, contact_id) {
			acte_ids = this.$sync.replaceWithReplicated('horse_actes', acte_ids)

            return this.$storage.db.transaction(
                'rw',
                ['horse_actes'],
                () => {
					acte_ids.forEach((acte_id) => {
						this.$storage.db.t('horse_actes')
						.then(table => {
							return table.update(parseInt(acte_id), { actes_contact: contact_id })
						})
					})
                }
            )
        },

        getHorseWithoutActs: async function(categories_ids, residence_id, actestypes_ids, start, end) {
			const params = {
				categories_ids,
				residence_id,
				actestypes_ids,
				start,
				end
			}
			const url = Constants.HORSE_WITHOUT_ACTS+"?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_post_api("ActesMixin::getHorseWithoutActs", url, params, false)
			.catch(e => {
                console.error("ActesMixin::getHorseWithoutActs => ", e)
                return null
			})

            if(result) return result.retour
            return []
        },

        getNbActesByGroup: async function(start, end, horses_ids = null) {
			let actes = []
			if(!horses_ids) {

				actes = await this.$storage.db.t('horse_actes')
					.then(table => {
						return table.where({actes_actesstatut: 0})
						.filter(acte => (acte.actes_date <= end && acte.actes_date >= start))
					})
					.then(col => {
						return col.transform(new ActeTransformer('withActeTypeGroupe'))
					})
			} else {
				actes = await this.$storage.db.t('horse_actes')
					.then(table => {
						return table.where({actes_actesstatut: 0})
						.filter(acte => (acte.actes_date <= end && acte.actes_date >= start && (horses_ids).includes(acte.actes_horse)))
					})
					.then(col => {
						return col.transform(new ActeTransformer('withActeTypeGroupe'))
					})
			}


	        const group_by = {}
	        actes.forEach(acte => {
				if(acte.horse_inactive) {
					return
				}
	        	if(!Object.prototype.hasOwnProperty.call(group_by, acte.actes_date)) {
	        		group_by[acte.actes_date] = {}
	        	}
	        	if(!Object.prototype.hasOwnProperty.call(group_by[acte.actes_date], acte.actetype.actestype_groupe.actesgroupe_label)) {
	        		group_by[acte.actes_date][acte.actetype.actestype_groupe.actesgroupe_label] = {nb: 0, code: acte.actetype.actestype_groupe.actesgroupe_code}
	        	}
	        	group_by[acte.actes_date][acte.actetype.actestype_groupe.actesgroupe_label].nb += 1
	        })

        	return group_by
        },

        getNbActesByType: async function(start, end, horses_ids = null) {
			let actes = []
        	if(!horses_ids) {
				actes = await this.$storage.db.t('horse_actes')
					.then(table => {
						return table.where({actes_actesstatut: 0})
						.filter(acte => (acte.actes_date <= end && acte.actes_date >= start))
					})
					.then(col => {
						return col.transform(new ActeTransformer('withActeTypeGroupe'))
					})
			} else {
				actes = await this.$storage.db.t('horse_actes')
					.then(table => {
						return table.where({actes_actesstatut: 0})
						.filter(acte => (acte.actes_date <= end && acte.actes_date >= start && (horses_ids).includes(acte.actes_horse)))
					})
					.then(col => {
						return col.transform(new ActeTransformer('withActeTypeGroupe'))
					})
			}

	        const group_by = {}
	        actes.forEach(acte => {
				if(acte.horse_inactive) {
					return
				}
	        	if(!Object.prototype.hasOwnProperty.call(group_by, acte.actes_date)) {
	        		group_by[acte.actes_date] = {}
	        	}
	        	if(!Object.prototype.hasOwnProperty.call(group_by[acte.actes_date], acte.actetype.actestype_label)) {
	        		group_by[acte.actes_date][acte.actetype.actestype_label] = {nb: 0, code: acte.actetype.actestype_groupe.actesgroupe_code}
	        	}
	        	group_by[acte.actes_date][acte.actetype.actestype_label].nb += 1
	        })

        	return group_by
        },

        getLastNumChaleur: async function(horse_id, date) {
        	const actes_type_saillie =  [
                "REPRO_INSMINATION_ARTIFICIELLE_EN_FRAIS",
                "REPRO_INSMINATION_ARTIFICIELLE_EN_CONGEL",
                "REPRO_INSMINATION_EN_RFRIGR",
                "REPRO_INSMINATION_ARTIFICIELLE_EN_RFRIGR_TRANSPORT",
                "REPRO_SAILLIE_EN_MAIN",
                "REPRO_SAILLIE_EN_LIBERT",
			]

			const actes_type_saillie_ids = await this.$storage.db.t('horse_actes_type')
			.then(table => {
				return table.where('actestype_code').anyOf(actes_type_saillie)
				.primaryKeys()
			})
			const actes_mare = await this.$storage.db.t('horse_actes')
            .then(table => {
                // Actes validés dans la saison, pour la jument
                return table.where({actes_actesstatut: 1, actes_horse: horse_id})
                .filter(acte => new Date(acte.actes_date) <= date && actes_type_saillie_ids.includes(acte.actes_actestype))
                .sortBy('actes_date')
            })
            .then(async array => {
                return await ActeTransformer.process(array, 'withReponseNotFormatted')
            })
            if(actes_mare.length == 0) {
            	return null
            }

            const rep_num = actes_mare.reverse()[0].reponse.find(rep => rep.question.question_code == 'Numéro de chaleur')
            if(rep_num) {
            	return rep_num.reponse_formatted_data
            }
            
            return null
        },

		printActs: async function(model_id, acte_ids, locale = "", format_id) {
			const params = {
				acte_ids: acte_ids,
				format_id: format_id
			}

            const url = this.constructRoute(Constants.PRINT_ACTS, {model_id}) + "?licence_key=" + Constants.USER_LICENCE_KEY + "&locale=" + locale
            const result = await this.$request.request_post_api("ActesMixin::printActs", url, params, false)
            .catch(error => {
                console.error("ActesMixin::printActs => ERROR", error)
                return null
            })

            Common.base64ToPdf(result, "actes.pdf")
            return true
        },
	}
}

export default ActesMixin
