var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.ids && _vm.ids.length > 0)?_c('div',[_c('div',{staticClass:"col-12"},[_c('CustomTable',{ref:"horse_table",attrs:{"id_table":"horse","items":_vm.horses,"busy":_vm.table_busy,"primaryKey":"horse_id","hrefsRoutes":_vm.config_table_hrefs,"hide_if_empty":true,"media-field":"media_filename","base-filters":_vm.filters,"externSlotColumns":['horse_tags', 'horse_wholesire', 'horse_tiers'],"groupByCustom":_vm.customGroupBy},on:{"update:busy":function($event){_vm.table_busy=$event}},scopedSlots:_vm._u([{key:"custom-slot-cell(horse_francise)",fn:function(ref){
var data = ref.data;
return [(data.horse_francise)?_c('font-awesome-icon',{staticClass:"text-success",attrs:{"icon":['fal', 'check-circle']}}):_c('font-awesome-icon',{staticClass:"text-danger",attrs:{"icon":['fal', 'times-circle']}})]}},{key:"custom-slot-cell(horse_tags)",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"row"},_vm._l((data.horse_tags),function(color){return _c('span',{key:color,staticClass:"tags",style:('background-color:'+color)})}),0)]}},{key:"custom-slot-cell(horse_wholesire)",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"row"},[_c('e-copybox',{attrs:{"value":data.horse_wholesire}})],1)]}},{key:"custom-slot-cell(horse_tiers)",fn:function(ref){
var data = ref.data;
return _vm._l((data.horse_tiers),function(tiers){return _c('router-link',{key:tiers.tiers_id,attrs:{"to":{name: 'tiersFiche', params: { tiers_id: tiers.tiers_id}}}},[_vm._v(" "+_vm._s(tiers.tiers_rs)+" "),_c('br')])})}}],null,true)})],1),_c('ModalPrintInfosHorse',{ref:"modal_print_infos_horse"}),_c('b-modal',{ref:"modal",attrs:{"hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [[_vm._v(" "+_vm._s(_vm.$t("horse.ajouter_pension"))+" ")]]},proxy:true}],null,false,2767763073)},[_c('PensionAjout',{ref:"PensionAjout",attrs:{"horse_ids":_vm.ids,"add-or-edit-ready":_vm.add_ready}}),_c('b-button',{staticClass:"mt-3 btn-block rounded-pill",attrs:{"block":"","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.checkForm($event)}}},[(_vm.processing)?_c('font-awesome-icon',{attrs:{"icon":['fas', 'spinner'],"pulse":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("global.ajouter")))],1)],1),_c('ModalEditQualification',{ref:"modal_edit_qualification"})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }